<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 21">
        <path d="M5.03612-.00012207h7.55528V1.31238H9.44334v2.64961c1.94386.15586 3.69896 1.01719 5.02506 2.33789l1.4559-1.51758.4447-.45937.8893.92695-.4447.46347-1.5032 1.5668c1.0586 1.43965 1.6882 3.23615 1.6882 5.18845 0 4.7127-3.6635 8.5313-8.18486 8.5313-4.52134 0-8.184834-3.8186-8.184834-8.5313 0-4.49118 3.329024-8.17028 7.555224-8.50661V1.31238H5.03612V-.00012207ZM1.88811 12.4686c0 1.9146.72966 3.7507 2.02847 5.1045 1.29881 1.3537 3.06037 2.1143 4.89716 2.1143 1.83676 0 3.59836-.7606 4.89716-2.1143 1.2988-1.3538 2.0285-3.1899 2.0285-5.1045 0-1.9145-.7297-3.75062-2.0285-5.1044-1.2988-1.35378-3.0604-2.11432-4.89716-2.11432-1.83679 0-3.59835.76054-4.89716 2.11432-1.29881 1.35378-2.02847 3.1899-2.02847 5.1044Zm7.55523-3.93747v5.24997H8.18413V7.87488h1.25921v.65625Z"/>
    </svg>
)

</script>
